@import '../../../../scss/theme-bootstrap';

.header-gnav-cart {
  &__icon-wrapper {
    position: relative;
  }
  &__trigger {
    width: 32px;
  }
  &__count {
    background-color: $cr19-bg-dark;
    border-radius: 50%;
    color: $cr19-text-white;
    display: block;
    font-family: $helvetica-regular-font-family;
    font-size: 10px;
    font-style: normal;
    height: 18px;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 4px;
    #{$rdirection}: 2px;
    width: 18px;
    &.hidden {
      display: none;
    }
  }
  &__overlay.visible {
    @media #{$cr19-large-up} {
      width: 664px; // @todo make this a var to share with search and sections
    }
  }
  &__content-wrapper {
    display: none;
    &.active {
      background: $color-white url('/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif')
        no-repeat scroll center 60px;
      display: block;
      height: 100%;
      margin-top: 12px;
      max-width: 100%;
      #{$rdirection}: 0;
      width: 100%;
      z-index: 9999;
      @media #{$cr19-large-up} {
        margin-top: 24px;
      }
    }
  }
}
